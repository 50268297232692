@import '../shared.module.scss';

$footerOverlayHeight: 180px;
$footerHeaderHeight: 60px;
$footerActionHeight: 40px;

.sellerItemContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    justify-content: center;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
    }

}

.item {
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    height: 550px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        height: 600px;
    }
}

.imageNavContainer { 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
    width: 100%;
    left: 0;
    z-index: 2;
    gap: 5px;
}

.imageNavItem {
    width: 30px;
    height: 4px;
    background-color: gray;
    cursor: pointer;
}

.imageNavItem.active {
    background-color: lightblue;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    top: 0;
    left: 0;
    z-index: 1;
}

.footerOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $footerOverlayHeight;
    background-color: black;
    /* border-top: solid 1px lightgray; */
    z-index: 2;
    opacity: .4;
}

.footerContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $footerOverlayHeight;
    background-color: transparent;
    z-index: 4;
    padding: 15px 20px;
    color: $pinkLight!important;
    display: flex;
    flex-direction: column;
}

.footerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 30px;;
    font-size: 1.25rem;
}

.nameAge {
    font-weight: 600;
    color: white;
}

.footerBody {
    font-size: 1rem;
    font-weight: 300;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    flex-grow: 1;
    color: lightgray;
}

.footerAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $footerActionHeight;
}

.footerAction > a {
    color: white;
    text-decoration: underline;
    font-size: 10pt;
    margin-top: 3px;
    color: $pinkLight!important;
    text-transform: uppercase;
}
.starIconContainer {
    /* position: absolute;
    top: 50px;
    left: 15px; */
    /* z-index: 3; */
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $pinkLight!important;
}

.starIcon {
    margin-right: 5px;
}

.starIcon:before {
    height: 16px!important;
    width: 16px!important;
    color: $pinkLight!important;
}