#birthday {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;

  }

#birthday .card {
    border-radius: 20px !important;
    border: 3px solid #5AE5CC !important;
    background: #000 !important;
}

.form-control {
    display: block;
    width: 100%;
    /* padding: 0.375rem 0.75rem !important; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.birth-btn {
    color: #fff;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    border-radius: 42px;
    padding: 12px 60px;
    transition: 0.4s;
}