#thankyou {
    width: 100%;
    height: 50vh;
    background: #fff;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

#thankyou .thank-container {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 15px;
}

#thankyou h2 {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 2vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}