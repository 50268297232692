.userDropdownToggle {
    background-color: transparent!important;
    display: flex!important;
    align-items: center!important;
    padding: 0!important;
}

.userDropdownMenu {
    background-color: black;
    min-width: 200px !important;
}