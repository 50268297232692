.fa--circle--user {
    font-size: 32px !important;
    color: white !important;

}

.fa--circle--user:hover {
    color: #E86669 !important;
}

.fa-shopping-cart {
    color: white;
}

.fa-shopping-cart:hover {
    color: #E86669;
}


.btn-user:focus {
    border: none;
    box-shadow: none;
}

.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10rem 10rem;
}

@media (max-width: 575.98px) {

    /* .btn-group {
        position: relative;
    } */




    .track-order-drop .dropdown-toggle {
        display: none;
        visibility: hidden;

    }
}

@media (max-width: 767.98px) {
    .track-order-drop .dropdown-toggle {
        display: none;
        visibility: hidden;

    }
}

#hotels .btn-btn {
    /* font-family: "Raleway", sans-serif; */
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px !important;
    border-radius: 10px;
    transition: 0.5s;
    /* line-height: 1; */
    margin: 10px;
    color: #000 !important;
    background: none;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #F6ECD0;

}

#hotels h6 {
    color: #000;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    /* line-height: 85px; */
    letter-spacing: 1px;
    display: inline;
    vertical-align: middle;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    /* border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; */
    color: white;
}


#my_dropdown {
    /* display: block; */
    background-color: black;
    color: white;
    border-color: 1px solid #212529;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: black;
    background-color: #E86669;
}

/* .user_drop {
    margin-top: 33px;
} */

.Profile {
    color: white;
}

.Sign_out {
    color: white;
}

.Profile:hover {
    color: #E86669;
    background-color: transparent;
}

.Sign_out:hover {
    color: #E86669;
    background-color: transparent;
}



.user_name {
    color: white;
}

/* .track-tooltip{
    padding: 0;
    color: white;
    background-color: black !important;
    transform: translate3d(1098px, 84.5px, 0px) !important;
    width: 18% !important;
   
} */

.tooltip-inner {
    max-width: 261px;
    padding: 0;
    color: #fff;
    text-align: center;
    background-color: transparent;
    border-radius: 0.25rem;
}

.tooltip {
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(1096.5px, 89px, 0px) !important;
    border: 1px solid white !important;
}

.tool-inner {
    color: black !important;
    background: #2f3138;
    border: 1px solid #1c1d20;
}



.tool_tip {
    margin: 0;
    padding: 2px !important;
    color: white;
    background-color: transparent !important;
    text-align: left;
}

.tool_tip:focus {
    color: white;
    background-color: black;
    border-color: black !important;
    box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5) !important;
}

.tooltip_product h6:hover {
    color: #E86669 !important;
}

.tool_tip h6 {
    margin: 0;

}

.tool_tip a {
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: smaller;
}



.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;

    left: 0px;
    transform: translate3d(125.5px, -8px, 0px) !important;
}



.tooltip {
    width: auto;
    background-color: #2f3138 !important;
    color: white;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    position: absolute;
    /* font-size: 0.83em; */
    font-weight: normal;
    border: 1px rgb(0, 0, 0) solid;
    /* z-index: 200000; */
    z-index: 15;
    /* max-width: initial; */
    max-width: 274px;
    text-align: start;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
    /* background: grey; */
}


.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #E86669;
}


.button-submit {
    position: absolute;
    top: 47.5%;
    right: 4%;
    font-size: 15.5px !important;
}

.button-submit .fa-angle-right {
    color: white;
    font-size: 18px;
}



#hotels .about-btn-men {
    /* font-family: "Raleway", sans-serif; */
    font-weight: 500;
    /* font-size: 14px; */
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 10px;
    transition: 0.5s;
    /* line-height: 1; */
    margin: 10px;
    color: #5BE8CF;
    background: none;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #5BE8CF;
}


#hotels .about-btn-men:hover {
    /* font-family: "Raleway", sans-serif; */
    font-weight: 500;
    /* font-size: 14px; */
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 10px;
    transition: 0.5s;
    /* line-height: 1; */
    margin: 10px;
    color: #000;
    background: none;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #5BE8CF;
    background: #5BE8CF;
}


#logo h1 {
    font-size: 31px;
    margin: 0;
    font-family: "Merriweather";
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;
}



.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.fa_heart {
    cursor: pointer;
    font-size: 1.1rem !important;
}

/* .fa_heart:hover {
    color: #E86669 !important;
} */

#heart {
    color: white;
    font-size: 26px;
}

#heart.red {
    color: red;
}

.user_drop button {
    background-color: black;
}

.dropdown-toggle {
    font: 400 1.5rem/1.25 sans-serif;
    color: white;
    /* padding: .5em 1em; */
    border: 2px solid white;
    border-radius: 0.8rem;
    padding-left: 26px;
}

.toggle-custom {
    font: 400 1.5rem/1.25 sans-serif;
    color: white;
    /* padding: .5em 1em; */
    /* border: 2px solid white; */
    /* border-radius: 0.8rem; */
    padding-left: 26px;
}

.dropdown-toggle1 {
    font: 400 1.5rem/1.25 sans-serif;
    color: white;
    /* padding: .5em 1em; */
    border: 2px solid white;
    border-radius: 0.8rem;
    padding-left: 26px;
}

.dropdown-toggle::before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f0c9';
    width: 15px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 17px;
    color: #E86669;
    font-size: 20px;
}

#dropdown-button-basic {
    font: 400 1.5rem/1.25 sans-serif;
    color: white;
    /* padding: .5em 1em; */
    border: none;
    border-radius: 0.4rem;
    padding-left: 16px
}

#dropdown-button-basic::before {

    padding-left: 26px;
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    content: '\f0c9';
    width: 15px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 17px;
    /* color: #E86669; */
    font-size: 20px;
}

.toggle-custom::before {
    color: transparent;
    background: none;
    display: block;
    font-family: 'FontAwesome';
    /* content: '\f0c9'; */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 17px;
    color: #E86669;
    font-size: 20px;
}

/* .dropdown-toggle:focus::before{
    color: #E86669;
  } */


@media (max-width: 575.98px) {

    /* #logo h1 {
        font-size: 20px;
        margin: 0;
        font-family: "Merriweather";
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
    } */
    .user_drop button {
        background-color: transparent !important;
        border: none !important;
    }

    .header_login #logo h1 a {
        font-size: 14px;
    }

    .header_login #logo h1 {
        line-height: 0.6;
    }

    .fa-user-large {
        font-size: 16px !important;
        color: white !important;

    }

    .icon-button__badge {
        position: absolute !important;
        /* bottom: 57px; */
        top: -5px !important;
        /* right: -37px; */
        width: 25px;
        height: 25px;
        background: red;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        left: 54% !important;
    }

    .fa-shopping-cart {
        font-size: 1.2rem;
        padding-top: 5px;
    }

    /* .user_name {
        display: none;
    } */

    .search input {
        height: 46px;
        text-indent: 10px;
        border: 2px solid #d6d4d4;
        border-radius: 0.8rem;
        /* width: 149px; */
        padding: 0;
    }

    .search .fa-search {
        position: absolute;
        top: 17px;
        right: 1rem;
        color: #E86669;
    }

    #hotels .btn-btn {
        /* font-family: "Raleway", sans-serif; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 12px;
        border-radius: 10px;
        transition: 0.5s;
        /* line-height: 1; */
        margin: 10px;
        color: #F6ECD0;
        background: none;
        animation-delay: 0.8s;
        border: 2px solid #F6ECD0;
    }

    #hotels .about-btn-men {
        /* font-family: "Raleway", sans-serif; */
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 12px 12px;
        border-radius: 10px;
        transition: 0.5s;
        /* line-height: 1; */
        margin: 10px;
        color: #5BE8CF;
        background: none;
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
        border: 2px solid #5BE8CF;
    }


    .dropdown-toggle {
        border: none;
    }

    /* .fa--circle--user {
        display: none;
    } */

    /* .t-order-btn{
        margin-bottom: 4rem;
    } */
    /* 
    .t-order-track{
        padding-top: 12rem !important;
    } */

}

@media (max-width: 767.98px) {

    .header_login #logo h1 a {
        font-size: 21px;
    }

    .dropdown-toggle {
        border: none;
    }

    .fa--circle--user {
        display: none;
    }

    /* .track-order-drop .dropdown-toggle {
        display: none !important;
        visibility: hidden !important;

    } */
    .user_drop button {
        background-color: transparent !important;
        /* border: 1px solid white; */
    }


}


/* @media (max-width: 991.98px) {
   

} */


@media (max-width: 1199.98px) {
    .user_drop button {
        background-color: black !important;
    }
}

@media (max-width: 991.98px) {

    #header #logo h1 {
        font-size: 20px !important;
        margin: 0;
        font-family: "Merriweather";
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    .user_drop button {
        background-color: transparent !important;
    }

    .Header-top {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: flex-start;
    }

    /* 
    .dropdown-toggle {
        border: none;
    } */

    /* .fa--circle--user {
        display: none;
    } */

    .track-order-drop .dropdown-toggle {
        display: none !important;
        visibility: hidden !important;

    }


}

.icon-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: white;
    background: none;
    border: none;
    outline: none;
    border-radius: 50%;
}

.icon-button__badge {
    position: absolute;
    /* bottom: 57px; */
    top: -12px;
    /* right: -37px; */
    width: 25px;
    height: 25px;
    background-color: black;
    background: black;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    left: 66%;
}

.bg-primary {
    --bs-bg-opacity: 1; 
    background-color: #E86669 !important;
    font-size: 12px;
}


.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0 solid;
    border-right: 0 solid transparent;
    border-bottom: 0;
    border-left: 0 solid transparent;
}

/* .btn .badge {
    position: relative;
    top: -1px;
    bottom: 2px;
} */

.favrt {
    position: relative;
}

.Product-spinner {
    position: absolute;
    top: 0.7rem;
    right: 6rem;
}

.Pro-description-spinner {
    position: absolute;
    top: 20%;
    right: 10%;
}


@media (max-width: 1399.98px) {
    .Product-spinner {
        position: absolute;
        top: 20%;
        right: 20%;
    }
}

@media (max-width: 1199.98px) {
    .Product-spinner {
        position: absolute;
        top: 20%;
        right: 5%;
    }
}

@media (max-width: 991.98px) {
    .Product-spinner {
        position: absolute;
        top: 20%;
        right: 35%;
    }
}

@media (max-width: 575.98px) {
    .buyer-profile-img img {
        display: none;

    }

    .Product-spinner {
        position: absolute;
        top: 20%;
        right: 40%;
    }
}


@media (max-width: 767.98px) {
    /* .buyer-profile-img img {
      display: none;
  
    } */

    .track-order-drop {
        display: none !important;
    }

}


/*=============================================================
                           Track-Orders
===============================================================*/



.Track-Order-Items {
    margin-top: 136px;
    margin-bottom: 68px;
    color: #bcbec2;

}

@media (max-width: 575.98px) {
    .Track-Order-Items {
        margin-top: 136px;
        margin-bottom: 68px;
        color: #bcbec2;

    }
}


@media (max-width: 991.98px) {
    .Track-Order-Items {
        margin-top: 23px;
        margin-bottom: 68px;
        color: #bcbec2;

    }
}

.Track-order-number {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}


.Track-order-number p {
    padding: 0;
    margin: 0 0 0 0;
}

.Track-order-pkg p,
h4 {
    padding: 0;
    margin: 0 0 0 0;
}


.fa-box-open {
    color: white !important;
    font-size: 1.2rem !important;
}

.Track-order-number h4 {
    padding: 0;
    margin-bottom: 0;
}

.pkg-1 {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}


.pkg-1 p {
    padding: 0;
    margin: 0 0 0 0;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #E86669 !important;
}

.Track-shipping p {
    padding: 0;
    margin: 0 0 15px 0;
}


.Track-shipping {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}

.Track-Billing {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}

.Track-Billing p {
    padding: 0;
    margin: 0 0 15px 0;
}


.Track-total-summary p {
    padding: 0;
    margin: 0 0 15px 0;
}

.Track-total-summary {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}

.txt-txt {
    color: #bcbec2;
    font-weight: 600;
}


.css-16ubnlw-MuiStepLabel-labelContainer {
    width: 100%;
    color: #bcbec2;
}

.css-qivjh0-MuiStepLabel-label.Mui-active {
    color: white !important;
    font-weight: 500;
}


.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #bcbec2 !important;
}

.css-117w1su-MuiStepIcon-text {
    fill: black !important;
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700 !important;
}

.css-qivjh0-MuiStepLabel-label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
    -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #bcbec2 !important;
}

/* .track-order-drop-two .dropdown-toggle::before {
    color: transparent;
    background: none;
    display: block;
    font-family: '';
    content: '';
    width: 15px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 17px;
    font-size: 20px;
    border-radius: rem;
    color: #E86669;
} */

.track-order-drop .dropdown-toggle::before {
    color: transparent;
    background: none;
    display: block;
    font-family: '';
    content: '';
    width: 15px;
    height: 20px;
    position: absolute;
    top: 13px;
    left: 17px;
    color: #E86669;
    font-size: 20px;
}

.track-order-drop .dropdown-toggle:hover,
.track-order-drop .dropdown-toggle:focus {
    color: white;
}


.track-order-drop .dropdown-toggle {
    font-size: 16px;
    color: white;
    /* padding: 0.5em 1em; */
    /* border-radius: 0.8rem; */
    padding: 6px;
    /* border-radius: 50%; */
    border: none;
}


.track-order-drop {
    background: #e86669;
    padding: 0px 1rem;
    border-radius: 2rem;
}


#Order-dropdown {
    z-index: 1000;
    min-width: 17.3rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: black;
    background-clip: padding-box;
    border: 1px solid rgba(8, 3, 3, 0.15);
    border-radius: 0.25rem;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 50px, 0px) !important;
}


#order-link a {

    font-size: 1px !important;
}

@media (max-width: 575.98px) {

    .Track-shipping h5 strong,
    .Track-Billing h5 strong,
    .Track-total-summary h5 strong {
        font-size: 18px;
    }

    .Track-shipping p {
        font-size: 14px;
    }

    .Track-Billing p {
        font-size: 14px;
    }

    .Track-total-summary small {
        font-size: 14px;
    }
}


/*=============================================================
                           My-Orders
===============================================================*/

.My-Orders {
    margin-top: 136px;
    margin-bottom: 68px;
    color: #bcbec2;
}

@media (max-width: 991.98px) {
    .My-Orders {
        margin-top: 37px;
        margin-bottom: 68px;
        color: #bcbec2;
    }
}

.My-order-items {
    border: 2px solid #171717;
    background-color: #171717;
    /* color: white; */
}


.My-order-items p,
h4 {
    padding: 0;
    margin: 0 0 0 0;
}

.Myorders-pkg-1 {
    border: 2px solid #171717;
    background-color: #171717;
}

.Myorders-pkg-1 p {
    padding: 0;
    margin: 0 0 0 0;
    ;
}

.order-refund {
    background-color: #686868;
    color: white;
    border-color: #686868;
    cursor: pointer;
}

.order-cancel {
    background-color: #E86669;
    color: white;
    border-color: #E86669;
    cursor: pointer;
}

.order-Completed {
    background-color: #2c812c;
    color: white;
    border-color: #2c812c;
    cursor: pointer;
}

.order-Processing {
    background-color: #E86669;
    color: white;
    border-color: #E86669;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
}


.myOrder {
    color: white;
}

.myOrders:hover {
    color: #E86669;
    background-color: transparent;
}

.myWishlist {
    color: white;
}

.myWishlist:hover {
    color: #E86669;
    background-color: transparent;
}

@media (max-width: 575.98px) {
    .txt-txt {
        font-size: 12px;
    }

    .warranty {
        font-size: .575em;
    }

    .qty {
        font-size: small;
    }

    .order-refund,
    .order-cancel,
    .order-Completed {
        font-size: 0.6em;
    }

    .myOrder-date-time a {
        font-size: 9px;
    }

    .All-order-data a {
        font-size: 14px;
    }

    .Myorders-pkg-1 p {
        font-size: 14px;
    }

}



/*=============================================================
                           My-Wishlist
===============================================================*/



.My-Wishlist {
    margin-top: 136px;
    margin-bottom: 68px;
    color: #bcbec2;
}

@media (max-width: 991.98px) {
    .My-Wishlist {
        margin-top: 23px;
        margin-bottom: 68px;
        color: #bcbec2;
    }
}

.fa-del {
    color: #E86669 !important;
    font-size: 1.1rem !important;
    cursor: pointer;
}

.fa_shop {
    color: white !important;
    font-size: 1.1rem !important;
    cursor: pointer;
}

.fa_shop:hover {
    color: #E86669 !important;
}

/* .shop_btn {
    background-color: #E86669;
    color: white;
} */

.Cut-price del {
    font-size: 13px
}

.add-to-cart-head {
    border: 2px solid #171717;
    background-color: #171717;
}

.Price {
    color: #E86669;
    font-weight: 600;
    font-size: 17px;
}


.p-drop {
    font-size: 14px;
    color: #2c812c;
    font-weight: 600;
}





@media (max-width: 575.98px) {
    .txt-txt {
        font-size: 12px;
    }

    .Color-family {
        font-size: .575em;
    }

    .p-drop {
        font-size: 11px;

    }

    .Cut-price {
        font-size: 11.3px
    }

    .Price {
        font-size: 15px;
    }

    .qty {
        font-size: small;
    }

    .order-refund,
    .order-cancel,
    .order-Completed {
        font-size: 0.6em;
    }

    .myOrder-date-time a {
        font-size: 9px;
    }

    .All-order-data a {
        font-size: 14px;
    }

    /* .fa-del {

        font-size: 1.1rem !important;

    }

    .fa_shop {

        font-size: 1.1rem !important;

    } */

    .order-Processing {
        font-size: 0.63rem;
        padding: 0.5rem 0.5rem;
    }

}

.toggle-custom {
    width: 10rem !important;
}

@media (max-width: 575.98px) {

    .track-order-drop-two .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    /* .track-order-drop-two {

        position: relative;
    } */
    .track-order-drop-two {
        position: relative;
        margin-top: 6rem !important;
        margin-left: 23px;
        margin-right: 23px;

    }

    .track-order-drop-two .dropdown-toggle {
        width: 20rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .track-order-drop-two button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop-two .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }

    .track-order-drop2 .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    /* .track-order-drop-two {

        position: relative;
    } */
    /* .track-order-drop2 {
        position: relative;
        margin-top: 6rem !important;
        margin-left: 23px;
        margin-right: 23px;

    } */

    .track-order-drop2 .dropdown-toggle {
        width: 18rem !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .track-order-drop2 button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop2 .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }

    .recent-orders-input {
        width: 100% !important;
    }

    .btn-danger {
        font-size: 14px;
    }

    .css-mjpk2e-MuiButtonBase-root-MuiStepButton-root {
        padding: 24px 13px !important;
    }

    .MuiStepLabel-label {
        font-size: 13px !important;
    }


}


@media (max-width: 767.98px) {

    .track-order-drop-two .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    .track-order-drop-two {

        position: relative;
    }

    .track-order-drop-two button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop-two .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }

    .track-order-drop2 .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    /* .track-order-drop2 {
        position: relative;
        margin-top: 2.5rem !important;
    } */

    .mobile-view-track {
        margin-top: -5.8rem;
    }

    .mobile-search {
        width: 100%;
        max-width: 22rem;
        margin: auto;
    }

    .track-order-drop2 button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop2 .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }
}



@media (max-width: 991.98px) {
    .track-order-drop-two .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    .track-order-drop-two {
        position: relative;
        margin-top: 9rem;
        margin-left: 23px;
        margin-right: 23px;

    }



    .track-order-drop-two .dropdown-toggle {
        width: 24rem;
        margin-left: auto;
        margin-right: auto;
    }

    .track-order-drop-two button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop-two .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }


    .track-order-drop2 .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: block !important;
    }

    .track-order-drop2 {
        position: relative;
        margin-top: 8rem;
        /* margin-left: 23px; */
        /* margin-right: 23px; */

    }

    .track-order-drop2 .dropdown-toggle {
        width: 24rem;
        margin-left: auto;
        margin-right: auto;
    }

    .track-order-drop2 button {
        position: absolute;
        bottom: 2%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-left: auto;
        background: #e86669;
        padding: 0px 1rem;
        border-radius: 2rem;
    }

    .track-order-drop2 .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }
}




@media (max-width: 1199.98px) {
    .track-order-drop-two .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        display: none;
    }

    .track-order-drop-two .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: block;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }

    .track-order-drop2 .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        /* display: none; */
    }

    .track-order-drop2 .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: none;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }
}



@media (max-width: 1399.98px) {

    .track-order-drop-two .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        /* display: none; */
    }

    .track-order-drop-two .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: none;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }

    .track-order-drop2 .dropdown-toggle {
        font-size: 16px;
        color: white;
        /* padding: 0.5em 1em; */
        /* border-radius: 0.8rem; */
        padding: 6px;
        /* border-radius: 50%; */
        border: none;
        /* display: none; */
    }

    .track-order-drop2 .dropdown-toggle::before {
        color: transparent;
        background: none;
        display: none;
        font-family: '';
        content: '';
        width: 15px;
        height: 20px;
        position: absolute;
        top: 13px;
        left: 17px;
        color: #E86669;
        font-size: 20px;
    }
}

/* .track-order-drop-two {
    display: none;
} */


.track-order-drop-two #dropdown-basic {
    display: none;
}

.track-order-drop2 #dropdown-basic {
    display: none;
}


#btn-btn-submit {
    right: 3% !important;
    width: 2rem !important;
    border-radius: 5px !important;
    position: absolute !important;
    top: 48%;
}

#btn-btn-submit .fa-angle-right {
    color: white;
    /* background-color: #E86669; */
    /* width: 2rem; */
    /* border-radi  us: 5px; */
    font-size: 18px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 0.6rem;

}


#resp-table {
    max-width: 100%;
    display: table;
    border: 2px solid #686868;
    /* padding: 6px; */
}

#resp-table-body {
    display: table-row-group;
}

.resp-table-row {
    display: table-row;
}

.table-body-cell {
    display: table-cell;
    /* border: 1px solid #dddddd; */
    /* border-right: 1px solid #686868; */
    padding: 2px;
    line-height: 1.42857143;
    vertical-align: top;
    padding-right: 1rem;
}

.read-more-less--more,
.read-more-less--less {
    color: #E86669;
}

@media (max-width: 991.98px) {
    .table-body-cell {
        padding-right: 0.6rem;
    }
}

@media (max-width: 767.98px) {
    .table-body-cell {
        padding-right: 1.6rem;
    }
}

@media (max-width: 1199.98px) {
    .table-body-cell {
        padding-right: 0.6rem;
    }
}

.about-btn-women {
    /* font-family: "Raleway", sans-serif; */
    font-weight: 500;
    /* font-size: 14px; */
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 10px;
    transition: 0.5s;
    /* line-height: 1; */
    margin: 10px;
    color: #E86669;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #E86669;
}

.about-btn-women:hover {
    /* background: #F6ECD0; */
    color: #000;
    border-color: #E86669;
    background-color: #E86669;
}

.css-1vyamtt-MuiStepLabel-labelContainer {
    width: 100%;
    color: #bcbec2 !important;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: #E86669 !important;
    font-weight: 500;
}

.btn-danger {
    white-space: nowrap;
}

.searchbar-drop {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    right: 0;
    transform: translateY(-50%);
    top: 39%;
    border-radius: 2rem;


}


.searchbar-drop #sb-drop {
    background: none;
    display: block !important;
    /* padding: 0; */
}

#sb-drop #dropdown-basic::before {
    color: none !important;
}

.popupClassName {
    background-color: #000000;
    color: white;

}

.ant-select-selector {
    background-color: #000000 !important;
    color: #ffffff !important;
    height: 44px !important;
    border-radius: 10px !important;
    border-color: white !important;
}

.ant-select-selector input {
    background-color: #000000 !important;
    color: #ffffff !important;
    height: 40px !important;
    border-radius: 10px !important;
    padding-left: 1rem !important;
}

.ant-select-selector input ::placeholder {
    background-color: #722f2f !important;
    color: #753b3b !important;
}

.ant-select-item-option-content {
    color: white !important;
}

.ant-select-item-option-content:hover {
    color: #E86669 !important;
}