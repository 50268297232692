@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/variables.scss';

.container {
    height: auto!important;
    min-height: auto!important;
    width: 100%;
    background-color: white;
    padding: 100px 0;

    @include media-breakpoint-up(lg) {
       height: 80vh;
    }
}

.infoContainer {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    height: 300px;
    padding: 40px 0;

    @include media-breakpoint-up(sm) {
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        height: 350px;
        flex-direction: row;

        > :not(:last-child) {
            border-right: solid 1px black;
        }
    }
}

.info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
    font-size: 20px;
    padding: 0 10px;
    text-align: center;

    @include media-breakpoint-up(sm) {
        height: 50%;
        font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
        height: 40%;
        font-size: 28px;
    }
}

.stayInTouchContainer {
    height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #F1F1F1;
    position: relative;
    padding: 0 10%;
}

.stayInTouchImageContainer {
    display: none;

    @include media-breakpoint-up(sm) {
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: center;
        img {
            margin: -10%;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.stayInTouchFormContainer {
    width: 100%;

    @include media-breakpoint-up(sm) {
        width: 60%;
        height: 100%;
        padding: 30px 0 0 30px;
        display: flex;
        height: 100%;
        // align-items: center;
        flex-direction: column;
    }
}

.title {
    font-size: 28px;

    span {
        color: $pink;
        font-weight: bold;
    }
}

.subTitle {
    font-size: 12px;
    margin-bottom: 10px;
}

.formInput {
    border-radius: 0!important;
}
.formButton {
    border-radius: 0!important;
    width: 70px;
}

.description {
    font-size: 10px;
}