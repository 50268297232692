
.clickable {
    cursor: pointer;
}

.container {
    position: relative;
}

.countIndicator {
    position: absolute;
    top: -8px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.indicatorInnerContainer {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.indicatorOverlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.7;
    z-index: 1;
}

.indicatorCount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    height: 100%;
    width: 100%;
    z-index: 2;
    text-align: center;

    font-weight: 600;
}