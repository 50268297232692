#benefits {
    background: #F47274;
    /* background-size: cover;
    overflow: hidden; */
    /* position: relative; */
    color: #fff;
    padding: 60px 0 40px 0;
}

#benefits h2 {
    color: #FFF;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 600;
    /* line-height: 85px; */
    letter-spacing: 1px;
}

#benefits p {
    color: #fff;
    font-family: Poppins;
    /* font-size: 20px; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

#benefits ul {
    list-style: none;
    padding: 0;

}

#benefits ul li {
    padding-bottom: 2rem;
    display: flex;
    align-items: center;
}

#benefits ul i {
    font-size: 30px;
    padding-right: 1rem;
    color: #fff;
}

@media (max-width:500px) {
    #benefits p {
        color: #FFF;
        font-family: Poppins;
        font-size: 13px !important;
        font-style: normal;
        font-weight: 300 !important;
        /* line-height: 85px; */
        letter-spacing: 1px;
    }

    #about-us h2 {
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        /* line-height: 85px; */
        letter-spacing: 1px;
    }

    #benefits h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 600;
        /* line-height: 85px; */
        letter-spacing: 1px;
    }

    .section-title {
        padding-bottom: 20px;
    }

    #benefits ul i {
        font-size: 0;
    }
}