#about-us {
    background: #fff;
    /* background-size: cover;
    overflow: hidden; */
    /* position: relative; */
    color: #000;
    padding: 60px 0 40px 0;
}

#about-us h2 {
    color: #000;
    font-family: Poppins;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    /* line-height: 85px; */
    letter-spacing: 1px;
}
@media (max-width:500px){
   
}