@import '../../../shared/styles/mixins.scss';
@import '../shared.module.scss';

.heroContainer {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    position: relative;
    margin-top: 80px;
}

.image {
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.innerContainer {
    position: absolute;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 0 10%;
}

.titleDescriptionContainer {
    width: 50%;
    color: white;

    h4 {
        font-size: 4rem;
        color: white;
        margin-bottom: 30px;
        font-weight: normal;
    }

    p {
        font-size: 1.25rem;
        margin-bottom: 60px;
        font-weight: 300;
    }

    .actionContainer {
        display: flex;
        gap: 20px;
    }


    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        h4 {
            font-size: 2.5rem;
        }

        p {
            font-size: 1rem;

        }
    }
}