@import '../../shared/styles/mixins.scss';

.container {
    width: 100%;
    height: 40px;
    color: white;
    background-color: black;
    opacity: 0.7;
    text-align: center;
    padding: 10px 0;
}