#otp {
    width: 100%;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;
}

#otp .card {
    border-radius: 20px !important;
    border: 3px solid #5AE5CC !important;
    background: #000 !important;
}

#otp input {
    width: 3rem !important;
    text-align: center;
    padding: 1rem;
    background: black;
    border-bottom: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin: 5px;
}

.Otpbtn {
    color: #fff;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    border-radius: 42px;
    padding: 12px 60px;
    transition: 0.4s;
}