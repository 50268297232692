.languageDropdownToggle {
    background-color: transparent!important;
    display: flex!important;
    align-items: center!important;
    padding: 0!important;
}

.languageDropdownMenu {
    background-color: black;
    
    button {
        color: white!important
    }
}