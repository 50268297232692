.loginbutton {
    color: #fff;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    border-radius: 42px;
    padding: 12px 60px;
    transition: 0.4s;
}