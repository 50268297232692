.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    background-color: black;
    color: white;
    padding: 20px 0;
    /* Remove position: absolute; and bottom: 0; */
    /* Add this line to make the footer sticky at the bottom */
    width: -webkit-fill-available;
}

.footer h3 {
    font-size: 1.75vw;
    color: #fff;
}

.footer-ul {
    line-height: 2.5rem;
}

.info h3 {
    margin: 0;
}

.infor {
    margin-top: 2.4rem;
}

.infor ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.info li {
    display: inline-block;
    margin: 10px;
}

.infor a {
    color: white;
    text-decoration: none;
}

.language {
    flex: 1;
    margin-top: 2.4rem;
}

.language h3 {
    margin: 0;
    color: #fff;
}

.language select {
    margin: 10px;
}

.buttons {
    display: flex;
}

.buttons button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding-left: 2rem;
    padding-right: 2rem;
}

.footer-buttons {
    background-color: #F47274;
    border-radius: 35px !important;
}

.footer-dropdown{
    outline: auto;
    outline-style: groove;
}

.footer-brand-description {
    padding-left: 10px;
    margin-bottom: 2rem;
}


@media (max-width: 768px) {
    .footer {
        flex-direction: column;
    }
    .footer_logo{
        width: 100%;
    }
    .logo {
        /* width: 150px; */
    }

    .info {
        text-align: center;
    }

    .info li {
        margin: 5px;
    }

    .language {
        text-align: center;
    }

    /* .buttons {
        justify-content: center;
    } */

    .buttons button {
        margin: 5px;
    }
}
@media (max-width:500px) {
    .footer h3 {
        font-size: 20px;
    }
    .logo {
        /* width: 150px; */
        padding-left: 0;
    }
}