#search-header {
  background: #fff;
  background-size: cover;
  /* overflow: hidden; */
  /* position: relative; */
  color: #000;
  padding: 60px 0 40px 0;
}

#search-header h2 {
  color: #000;
  font-family: Poppins;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  /* line-height: 85px; */
  letter-spacing: 1px;
}

#search-header .search {
  position: relative;
}

#search-header .search:focus-visible {
  outline: none !important;
}

#search-header .search:focus-visible {
  outline: none !important;
}

#search-header .search .search-input {
  background-color: #fff;
  height: 46px;
  width: 100%;
  border-radius: 2rem;
  border: 1px solid #cdcdcd;
  color: #666;
  font-size: 16px;
  text-indent: 10px;
  padding: 0px 15px 0px 45px;
}

#search-header .search .search-input:focus-visible {
  outline: none !important;
}

#search-header .search .search-input:focus-visible {
  outline: none !important;
}

#search-header .search i {
  position: absolute !important;
  color: #666;
  left: 20px !important;
  top: 16px !important;
  width: 18px !important;
  height: 18px !important;
  font-size: 16px !important;
}

#search-header .search .search-btn {
  position: absolute !important;
  top: 0;
  right: 0px;
  bottom: 0;
  border: 0;
  height: 46px;
  background: none;
  font-size: 16px;
  text-indent: 0px;
  padding: 0 20px;
  background: #F47274;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 2rem 2rem 0;
}

#search-header .btn-interest {
  font-family: Poppins;
  font-size: 16px;
  /* font-size: 1vw; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #F47274 !important;
  border: 0;
  letter-spacing: 1px;
  display: inline-block;
  height: 46px;
  align-items: center;
  padding: 0vw 2vw;
  margin-right: 0.8vw;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  cursor: pointer;
  animation-delay: 0.8s;
  /* width: -webkit-fill-available; */
}

#search-header .btn-interest :hover {
  background: #E86669 !important;
}


#breadcrumb {
  background: #fff;
  background-size: cover;
  overflow: hidden;
  position: relative;
  color: #000;
  padding: 20px 0;
}


.feature-prod .member {
  margin-bottom: 20px;
  overflow: hidden;
  /* text-align: center; */
  border: 1px solid #ccc;
  border-radius: 12px;
  background: #fff;
  width: 100% !important;
  box-shadow: 0px 2px 15px rgba(63, 187, 192, 0.1);
}

.feature-prod .member .member-img {
  position: relative;
  overflow: hidden;
}

.feature-prod .member .member-img .image-item {
  width: 100%;
  height: 16vw;
}

.feature-prod .member .member-info {
  padding: 1.25vw;
}

.feature-prod .member .member-info h4 {
  color: #F47274;
  font-family: Poppins;
  /* font-size: 25px; */
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.feature-prod .member .member-info h5 {
  color: #000;
  font-family: Poppins;
  /* font-size: 20px; */
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0 !important;
}

.feature-prod .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.feature-prod .member .member-info p {
  min-height: 80px;
  color: #000;
  font-family: Poppins;
  /* font-size: 20px; */
  font-size: 1vw;
  /* font-style: italic; */
  font-weight: 300;
  line-height: normal;
  padding: 0 !important;
}

.feature-prod .member .member-info button {
  color: #FFF;
  font-family: Poppins;
  /* font-size: 16px; */
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #E86669;
  border: 0;
  letter-spacing: 1px;
  display: inline-block;
  padding: 0.6vw;
  margin-right: 0.8vw;
  transition: 0.4s;
  border-radius: 50px;
  cursor: pointer;
  animation-delay: 0.8s;
  width: -webkit-fill-available;
}

.feature-prod .member .member-info i {
  color: #F47274;
  cursor: pointer;
  font-size: 1.8vw;
}

.feature-prod .see-more {
  color: #F47274;
  background-color: #fff;
  font-family: Poppins;
  /* font-size: 30px; */
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border: 2px solid #F47274;
  border-radius: 50px;
  padding: 4px 60px;
  transition: 0.4s;
}

.feature-prod .see-more:hover {
  color: #fff;
  background-color: #F47274;
}

@media (max-width: 575.98px) {
  .feature-prod .member .member-img .image-item {
    width: 100%;
    height: 16vw;
  }

  .feature-prod .member .member-info {
    padding: 1.25vw;
  }

  .feature-prod .member .member-info h4 {
    color: #F47274;
    font-family: Poppins;
    /* font-size: 25px; */
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .feature-prod .member .member-info h5 {
    color: #000;
    font-family: Poppins;
    /* font-size: 20px; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0 !important;
  }

  .feature-prod .member .member-info span {
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
  }

  .feature-prod .member .member-info p {
    min-height: 80px;
    color: #000;
    font-family: Poppins;
    /* font-size: 20px; */
    font-size: 1vw;
    /* font-style: italic; */
    font-weight: 300;
    line-height: normal;
    padding: 0 !important;
  }

  .feature-prod .member .member-info button {
    color: #FFF;
    font-family: Poppins;
    /* font-size: 16px; */
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #E86669;
    border: 0;
    letter-spacing: 1px;
    display: inline-block;
    padding: 7px;
    margin-right: 0.8vw;
    transition: 0.4s;
    border-radius: 50px;
    cursor: pointer;
    animation-delay: 0.8s;
    width: -webkit-fill-available;
  }

  .feature-prod .member .member-info i {
    color: #F47274;
    cursor: pointer;
    font-size: 1.8vw;
  }

  .feature-prod .see-more {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    /* font-size: 30px; */
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
  }

  .feature-prod .see-more:hover {
    color: #fff;
    background-color: #F47274;
  }

  #search-header .search {
    margin-bottom: 20px;
    position: relative;
  }

  .feature-prod .member .member-info p {
    min-height: 60px;
    color: #000;
    font-family: Poppins;
    /* font-size: 20px; */
    font-size: 1vw;
    /* font-style: italic; */
    font-weight: 300;
    line-height: normal;
    padding: 0 !important;
  }

  #search-header .btn-interest {
    font-family: Poppins;
    font-size: 13px;
    /* font-size: 1vw; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: #F47274 !important;
    border: 0;
    letter-spacing: 1px;
    display: inline-block;
    height: 46px;
    align-items: center;
    padding: 0vw 2vw;
    margin-right: 0.8vw;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    cursor: pointer;
    animation-delay: 0.8s;
    width: -webkit-fill-available;
  }

  #search-header .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-top: var(--bs-gutter-y);
  }

  .filter-row {
    overflow-x: scroll;
    display: flex !important;
    align-items: center;
  }

  .filter-row::-webkit-scrollbar {
    display: none;
  }

  #hotels h6 {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 85px; */
    letter-spacing: 1px;
    display: inline;
    vertical-align: middle;
    margin: 0;
  }

  .breadcrumb {
    display: flex;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
    justify-content: center;
    align-items: center;
  }

  #breadcrumb {
    padding: 0;
  }

  #hotels {
    padding: 0rem 0rem !important;
  }

  .row.feature-prod {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }

  #search-header {
    margin-top: 55px;
    padding: 60px 0 17px 0;

  }
  .filter-row-paginate{
    display: none;
  }
}