@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/variables.scss';

.container {
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;


    @include media-breakpoint-down(sm) {
        height: auto;
    }
}

.mainContainer {
    flex-grow: 1;
    background-color: black;
    display: flex;
    width: 100%;
    align-items: center;
    color: white;

    > :nth-child(1) {
        width: 28%;
    }
    > :nth-child(2) {
        width: 18%;
    }
    > :nth-child(3) {
        width: 18%;
    }
    > :nth-child(4) {
        width: 18%;
    }
    > :nth-child(5) {
        width: 18%;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;

        > :nth-child(1), > :nth-child(2), > :nth-child(3), > :nth-child(4), > :nth-child(5) {
            width: 100%;
        }
    }
}

.detailsItem {
    height: 100%;
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    padding: 40px 20px 20px 20px;

    .title {
        font-weight: normal;
        margin-bottom: 30px;
        font-size: 1rem;
        width: 100%;
    }

   .listItem {
        color: white;
        font-size: 1rem;
        width: 100%;
        text-decoration: none;
        font-weight: 300;
        &:hover {
            color:$pink;
            text-decoration: underline; 
          }
   }

   .contactItem {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;

        .contactIcon:not(.locationIcon) {
            margin-right: 20px;
            min-height: 24px;
            min-width: 24px;
        }

        .locationIcon {
            margin-right: 20px;
            min-height: 24px;
            min-width: 24px;
        }

   }
}

.socialIconContainer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: black;
}

.bottomContainer {
    height: 40px;
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.container .imageModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.container .imageModal img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 80%;
    max-height: 80%;
    display: block;
    object-fit: contain;;
}

.container .imageModal span {
    position: absolute;
    top: 3px;
    right: 15px;
    font-size: 35px;
    font-weight: 300;
    cursor: pointer;
    color: #ffffff;
    z-index: 1000;
    user-select: none;
}

.logo {
    height: 25%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}