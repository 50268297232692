@import '../../../shared/styles/mixins.scss';
@import '../shared.module.scss';

.categoriesContainer {
    display: grid!important;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    max-height: 1200px;

    > :nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
        height: 100%;
        min-height: 400px;
    }

    > :nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        height: 100%;
        min-height: 400px;
    }

    > :nth-child(3) {
         /* middle picture */
        grid-area: 1 / 2 / 3 / 4;
        height: 100%;
        min-height: 400px;
    }

    > :nth-child(4) {
        grid-area: 1 / 4 / 2 / 5;
        height: 100%;
        min-height: 400px;
    }

    > :nth-child(5) {
        grid-area: 2 / 4 / 3 / 5;
        height: 100%;
        min-height: 400px;
    }


    @include media-breakpoint-down(md) {

        // 2 columns grid
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 25px;
        max-height: none;

        > :nth-child(1) {  grid-area: 1 / 1 / 2 / 2; height: 600px; }
        > :nth-child(2) {  grid-area: 1 / 2 / 2 / 3; height: 600px; }
        > :nth-child(3) {  grid-area: 2 / 1 / 3 / 2; height: 600px; }
        > :nth-child(4) {  grid-area: 2 / 2 / 3 / 3; height: 600px; }
        > :nth-child(5) {  grid-area: 3 / 1 / 4 / 2; height: 600px; }

    }

    @include media-breakpoint-down(sm) {

        //1 column grid
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 25px;
        max-height: none;

        > :nth-child(1) {  grid-area: 1 / 1 / 2 / 2; }
        > :nth-child(2) {  grid-area: 2 / 1 / 3 / 2; }
        > :nth-child(3) {  grid-area: 3 / 1 / 4 / 2; }
        > :nth-child(4) {  grid-area: 4 / 1 / 5 / 2; }
        > :nth-child(5) {  grid-area: 5 / 1 / 6 / 2; }
    }
}

.item {
    width:100%;
    height: 100%;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    top: 0;
    left: 0;
    z-index: 1;
}

.titleActionContainer {
    position: absolute;
    z-index: 2;
    height: auto;
}

.title {
    margin-bottom: 20px;
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
}

.topLeft {
    top: 40px;
    left: 20px;
}

.topRight {
    top: 40px;
    right: 20px;
}

.bottomLeft {
    bottom: 20px;
    left: 20px;
}

.bottomRight {
    bottom: 20px;
    right: 20px;
}
