.container {
    width: 100%;
    border: solid 1px black;
    border-radius: 5px;
    padding: 20px;
    // height: 100%;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header {
        font-size: 1.5rem;
        font-weight: bold;
    }

    a {
        font-size: .825rem;
    }
}


.accordionItem {
    border: none;
}
.accordionHeader {
    padding: 0;
    border: none;
    border-radius: 0;
    outline: 0;

    button {
        font-size: 14px;
        color: black;
        padding:10px 0;
        border-radius: 0;
        outline: 0;

        &:hover {
            color: black;
        }
    }

    button::after {
        color: black;
    }
}
.accordionBody {
    background-color:white;
    border: none;
    border-radius: 0;
    padding: 10px 0;
    outline: 0;
    font-size: 14px;
}

.checkBox {
    border-radius: 0!important;
    label {
        text-transform: capitalize!important;
    }
}
