.cont {
    width: 100%;
    height: 100%;
    font-family: Arial;
}

.cont {
    padding: 30px;
}

.top {
    font-size: 0;
}

.top-left {
    /* width: 50%; */
    font-size: 16px;
    /* display: inline-block;
    vertical-align: top; */
}

.top-left .client {
    margin-top: 40px;
    margin-left: 40px;
}

.top-right {
    /* width: 50%; */
    font-size: 16px;
    /* display: inline-block;
    vertical-align: top; */
}

.top-right h2 {
    margin-bottom: 20px;
}

.top-right .split {
    font-size: 0;
}

.top-right .split .split-left,
.top-right .split .split-right {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.top-right .split .split-right {
    width: 60%;
}

.items {
    margin-top: 50px !important;
}

.items .items-row {
    font-size: 0;
    overflow-x: auto;
    padding-left: 0;
    padding-right: 0;
}

.items .items-row .items-row-cell {
    padding: 6px 14px;
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    border-bottom: 1px solid #f1f1f1 !important;
    color: white;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}

.items .items-row .items-row-cell.header {
    padding: 14px 14px;
    font-weight: bold;
    background: #000000;
}

.items .items-row .title {
    width: 20%;
}

.items .items-row .quantity {
    width: 20%;
}

.items .items-row .unit-price {
    width: 20%;
}

.items .items-row .vat-rate {
    width: 20%;
}

.items .items-row .sum {
    width: 20%;
}

.items-row .items-row-cell{
    white-space: nowrap;
}

.summary {
    margin-top: 50px;
    padding: 15px;
    font-size: 0;
    background: #000000;
}

.summary .summary-title {
    width: 100%;
    margin-bottom: 30px;
    font-size: 20px;
}

.summary .summary-left {
    /* width: 65%;
    display: inline-block;
    vertical-align: top; */
    font-size: 16px;
}

/* .summary .summary-left strong {
    width: 200px;
    display: inline-block;
} */

.summary .summary-right {
    /* width: 35%;
    display: inline-block;
    vertical-align: top; */
    font-size: 16px;
}

.summary .summary-right .split {
    font-size: 0;
}

.summary .summary-right .split .split-right,
.summary .summary-right .split .split-left {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
}

.summary .summary-right .split .split-right {
    text-align: right;
}