#payment {
    padding: 20px 0;
    background-color: #fff;
    color: #000;
}

.payment-options {
    display: flex;
}

#breadcrumb {
    background: #fff;
    background-size: cover;
    overflow: hidden;
    position: relative;
    color: #000;
    padding: 30px 0;
}

.visa-label {
    background-image: url('../../../../assets/images/png/visa.png');
    /* Replace with your VISA logo URL */
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    /* Adjust padding as needed */
    width: 80px;
    height: 46px;
}

.stripe-label {
    background-image: url('../../../../assets/images/png/Stripe.png');
    /* Replace with your VISA logo URL */
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    /* Adjust padding as needed */
    width: 80px;
    height: 46px;
}

.mastercard-label {
    background-image: url('../../../../assets/images/png/mastercard.png');
    /* Replace with your MasterCard logo URL */
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    /* Adjust padding as needed */
    width: 80px;
    height: 46px;
}

.paynow-label {
    background-image: url('../../../../assets/images/png/paynow.png');
    /* Replace with your PAY NOW logo URL */
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 20px;
    /* Adjust padding as needed */
    width: 80px;
    height: 46px;
}

#payment .card {
    background-color: white !important;
    border-radius: 10px !important;
    padding: 20px !important;
    border: 2px solid #cdcdcd !important;
}

#payment .card .table {
    width: 100%;
    /* Make the table occupy full width of the card */
    border-collapse: collapse !important;
    /* Merge borders of table cells */
    border: none !important;
}

#payment table th {
    background: none !important;
    color: #000;
}

#payment .card .table-equal-columns th {
    padding: 8px;
}

#payment .card .table-equal-columns td {
    width: 50%;
    /* Set equal width for table columns */
    padding: 8px;
    /* Adjust padding as needed */
}

#payment .card .table-equal-columns td:first-child {
    width: 70%;
    /* Set width for the first column */
    border-right: 0;
    /* Remove right border for the first column */
}

#payment .card .table-equal-columns td:last-child {
    width: 30%;
    /* Set width for the last column */
    border-left: 0;
    /* Remove left border for the last column */
}

#payment .btn-proceed {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
}

#payment .btn-proceed:hover {
    background-color: #E86669;
    color: #FFF;
}

#payment .btn-cancel {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
}

#payment .btn-cancel:hover {
    background-color: #f47274;
    color: #fff;
}

#payment h4 {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#payment .card h4 {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.payment-option {
    width: 80px;
    height: 50px;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.payment-option img {
    width: 100%;
    /* Set your desired width */
    margin-right: 10px;
}

.payment-option.selected {
    background-color: #e6f7ff;
    /* Set your desired background color for the selected state */
    border: 1px solid #1890ff;
    /* Set your desired border color for the selected state */
}

@media (max-width:500px) {
    #payment h4 {
        color: #000;
        text-align: justify;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    #breadcrumb {
        margin-top: 110px;
        background: #fff;
        background-size: cover;
        overflow: hidden;
        position: relative;
        color: #000;
        padding: 30px 0;
    }
    #payment .btn-proceed {
        font-size: 13px;
        color: #FFF;
        background-color: #F47274;
        font-family: Poppins;
        /* font-size: 1.25vw; */
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 2px solid #F47274;
        border-radius: 50px;
        padding: 1px 25px;
        transition: 0.4s;
    }
    #payment .btn-cancel {
        color: #F47274;
        background-color: #fff;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 2px solid #F47274;
        border-radius: 50px;
        padding: 1px 25px;
        transition: 0.4s;
    }
    #payment .card h4 {
        color: #000;
        text-align: justify;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}