#signup {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #000;

  }

#signup .card {
    border-radius: 20px !important;
    border: 3px solid #5AE5CC !important;
    background: #000 !important;
}


#signup .spinner-set {
    position: absolute;
    right: 6rem;
    top: 18%;
    color: #f8f9fa;
    /* size: 20; */
    /* font-size: 4rem; */
    /* transform: scale(1); */

    /* font-size: 22px; */
}


/* .spinner2-set {
    position: absolute;
    right: 6rem;
    top: 18%;
    color: black;
} */

.scroll_form {
    scroll-behavior: smooth !important;
}


.btn-next {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-next:hover,
.btn-next:focus {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.forms-container {
    margin-top: 5rem;
}

.PhoneInputInput {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0.25rem;
    color: #000;
    padding: 6px 12px;
}

/* .PhoneInputInput--focus {
    border: none;
} */


/* .PhoneInputInput:focus {
    background-color: transparent !important;
    border: none !important;
} */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
    background: lightgray;
    line-height: 2;
    padding: 10px;
    border-radius: 10px 0px 0px 10px;
}

.SignUp-btn {
    color: #fff;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    border-radius: 42px;
    padding: 12px 60px;
    transition: 0.4s;
}