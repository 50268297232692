/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/


body {
    overflow-x: hidden;
    overflow-y: hidden;
}

/* #header {
    height: 100px !important;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.5s;
    z-index: 997;
  } */
header.sticky {
    background: rgba(0, 0, 0, 0.98);
}

header.sticky ul li a {
    color: white;
}

header.sticky .logo {
    color: black;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-light .navbar-nav .nav-brand {
    color: white;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #E86669;
    color: white;
    cursor: pointer;
    padding: 10px 14px 10px 14px;
    border-radius: 50%;
    font-size: 24px;
    transition: all 0.4s;

}

#myBtn:hover {
    background-color: #e0072f;
}


.navbar-light .navbar-brand {
    color: #F6ECD0;
    font-size: 36px;
}

.cart-icon-container {
    position: relative;
    display: inline-block;
}

.customBadge {
    text-align: center;
    position: absolute;
    top: -13px;
    right: -10px;
    padding: 1px 7px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_dropdown {
    /* display: block; */
    background-color: black;
    color: white;
    position: absolute !important;
    transform: translate(-10px, 63px) !important;
}

/* #header_dropdown .my_dropdown .dropdown-item:hover {
    background-color: transparent;
} */

.lang_dropdown {
    /* display: block; */
    min-width: 6rem;
    background-color: black;
    color: white;
    position: absolute !important;
    transform: translate(-8px, 40px) !important;
}

.lang_dropdown .dropdown-item {
    color: #fff;
    ;
}

.nav__mobileCollapse {
    margin-top: 55px;
    background-color: black;
}

.nav__mobileCollapse ul li {
    border-bottom: 1px solid grey;
    padding-top: 7px;
    text-align: center;
    text-decoration: none;
}

.nav__mobileCollapse ul {
    padding-bottom: 10px;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.nav__mobileCollapse p {
    padding-bottom: 0;
    margin-bottom: 0;
}

.nav__mobileCollapse .btn-primary {
    /* color: black;
    background-color: transparent;
    border-color: black; */
    border: 3px;
    font-size: small;
    color: white !important;
    background-color: #F47274;
    border-color: #F47274;
    padding: 8px 24px !important;
}

@media (max-width:500px) {
    .customBadge {
        text-align: center;
        position: absolute;
        top: -13px;
        right: -10px;
        padding: 1px 6px;
        border-radius: 50%;
        font-size: 11px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}