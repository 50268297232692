@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/variables.scss';

.container {
    position: fixed;
    top: 0;
    height: $headerHeight;
    width: 100%;
    z-index: 999;
}

.innerContainer {
    position: relative;
    height: 100%;
    width: 100%;
}

.overlay {
    position: absolute;
    top:0;
    left: 0;
    background-color: #000000;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.headerContentContainer {
    position: absolute;
    top:0;
    left: 0;
    padding: 0 40px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.logo {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
        height: 60%;
        padding: 0 30px 0 0;
    }
}

.navContainer {
    display: flex;
    align-items: center;
    height: 100%;
    flex-grow: 1;
    padding: 0 20px;

    .navItem {
        color:  white;
        margin-right: 35px;
        font-size: 16px;
        font-weight: 200;
        text-decoration: none;

        &:hover {
            color: $pink;
        }
    }

    @include media-breakpoint-down(md) {
        .navItem {
            display: none;
        }
    }
}

.rightActionContainer {
    display: flex;
    align-items: center;
    width: 300px;
    justify-content: flex-end;
    height: 100%;

    @include media-breakpoint-down(md) {
        flex-grow: 1;

        .icon, .language{
            display: none;
        }

        .hamburgerIcon {
            display: block;
        }
    }

}

.icon {
    margin-left: 20px;
}

.hamburgerIcon {
    display: none;
}


.userDropdownToggle {
    background-color: transparent!important;
    display: flex!important;
    align-items: center!important;
    padding: 0!important;
}

.userDropdownMenu {
    background-color: black;
}