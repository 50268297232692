
@import '../../shared/styles/variables.scss';
@import '../../shared/styles/mixins.scss';

.sectionTitle{
    font-weight: 300;
    font-size: 2.5rem;
    span {
        color: $pink;
        // font-weight: bold;
    }
}

.sectionSubTitle {
    font-size: 1.25rem;
    color: #4F4F4F;
}

.container {
    width: 100%;
    padding: 40px 20px;
    min-height: 100vh;

    @include media-breakpoint-up(md) {
        padding: 40px 40px;
    }

    @include media-breakpoint-up(lg) {
        padding: 40px 80px;
    }
}