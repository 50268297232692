@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.customToggle {
  &::after {
    display: none !important;
  }
}

.suggestionsContainer {
  border:1px solid $dropdown-border-color;
  list-style: none;
}

.suggestion {
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: $dropdown-link-hover-bg;
  }
}