@import '../../../shared/styles/mixins.scss';

.hero {
    height: 450px;
    width: 100%;
    background-color: red;
    background-image: url(https://images.unsplash.com/photo-1568819317551-31051b37f69f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2V4eXxlbnwwfHwwfHx8MA%3D%3D);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10%;
    color: white;
    margin-bottom: 40px;


    h5 {
        font-size: 35px;
        padding: 0;
        font-weight: bold;
       
    }

    h3 {
        font-size: 90px;
        margin-bottom: 20px;
        font-weight: bold;
    }

    span {
        font-size: 30px;
    }
    
}

.topLayout {
    display: flex;

    .leftPanel {
        width: 350px;
        padding-right: 10px;
    }

    .rightPanel {
        flex-grow: 1;
        padding-left: 10px;
    }
}

.contentLayout {
    display: grid;
    grid-template-columns: 350px auto;
    margin-top: 20px;
    gap: 20px;
    margin-bottom: 80px;
}


.interestButton {
    width: 100%;
    text-transform: capitalize;
    font-size: 16px!important;
}

.searchContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchSortContainer {
        display: flex;
        align-items: center;
    }
}

.productsContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .product {
        width: 100%;
        height: 500px;
    }
}

.paginationContainer{
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}


