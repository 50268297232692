#login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #000 !important;
}

#login .card {
  border-radius: 20px !important;
  border: 3px solid #5AE5CC !important;
  background: #000 !important;
  height: 45rem !important;
}

#login button:disabled {
  background-color: #b17979 !important;
  color: #fff ;
}