.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
}

.round {
    border-radius: 50%;
}

.square {
    border-radius: 5px;
}


.initials {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }
  
