@import '../../shared/styles/mixins.scss';

$overlayHeight: 28%;

.container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &.rounded {
        border-radius: 8px;
    }
}

.image {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: 50% 50%;
    z-index: 1;
}

.overlay {
    min-height: $overlayHeight;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: black;
    opacity: 0.7;
}

.descriptionContainer {
    min-height: $overlayHeight;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: white;
}

.titlePriceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: normal;
}

.footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.categoryName {
    flex-grow: 1;
    font-weight: 100;
    font-size: 1rem;
}

.seller {
    display: flex;
    align-items: center;
}

.sellerName {
    margin-left: 8px;
}

.heartIconContainer {
    height: 24px;
    width: 24px;
    background-color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 4;
}