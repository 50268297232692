#wishlist .btn-addToCart {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 36px;
    transition: 0.4s;
}

#wishlist .btn-addToCart:hover {
    background-color: #E86669;
    color: #FFF;
}

#wishlist .card {
    padding: 0;
}

#wishlist table,
thead,
th {
    border: none !important;
}

#wishlist tbody tr:not(:last-child) {
    border-bottom: 1px solid #dcdcdc !important;
}

#wishlist table thead {
    background-color: #F5F5F5 !important;
    color: #000;
    border-radius: 10px;
    /* line-height: 40px; */
}

#wishlist table th {
    background: none !important;
    color: #000;
}

.table-bordered,
td,
th,
tr {
    border-color: inherit;
    border-style: solid;
    border-width: 1px;
    vertical-align: middle;
    white-space: nowrap;
    padding: 0.7rem 0;
}

#wishlist .form-check {
    display: block;
    min-height: auto;
    /* padding-left: 2.75rem;
    margin-bottom: 0.125rem; */
    display: flex;
    justify-content: center;
}

/* #wishlist table tbody td:nth-child(1),
.table td:nth-child(2),
.table td:nth-child(3),
.table td:nth-child(4) {
    width: 15%;
} */

/* #wishlist table tbody td:nth-child(2) {
    width: 70%;
} */

.description-column {
    width: 50%;
    max-width: 50%;
    word-wrap: break-word;
}

#wishlist .table tbody td:not(:last-child) {
    border-bottom: 1px solid #cdcdcd;
}

@media(max-width:500px) {
    #wishlist .btn-addToCart {
        color: #FFF;
        background-color: #F47274;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 2px solid #F47274;
        border-radius: 50px;
        padding: 4px 36px;
        transition: 0.4s;
    }

    .wishlist-productDetails {
        padding-top: 19px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .wish .img-fluid {
        max-width: 70px;
        height: auto;
        margin-right: 0 !important;
    }
}