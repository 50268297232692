@import '../../../shared/styles/mixins.scss';
@import '../shared.module.scss';

.container {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
}

.carouselContainer {
    width: 100%;
    margin-top: 30px;

    .carouselInnerContainer {
        width: 100%;
        grid-template-columns: 1fr;
    }

    @include media-breakpoint-down(sm) {

        .carouselInnerItem {
            grid-template-columns: repeat(1, 1fr);
        }
    }


    @include media-breakpoint-up(sm) {

        .carouselInnerItem {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @include media-breakpoint-up(md) {

        .carouselInnerItem {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @include media-breakpoint-up(lg) {

        .carouselInnerItem {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    // @include media-breakpoint-down(sm) {

    //     .carouselInnerItem {
    //         grid-template-columns: 1fr;
    //     }
    // }

}



.carouselItem {
    height: 600px;
}

.carouselInnerItem {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.actionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}