@import '../../../shared/styles/mixins.scss';
@import '../shared.module.scss';

$image-min-height: 500px;
$image-max-height: 500px;


.container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 400px 1fr;
    margin-bottom: 50px;
    align-items: center;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
        .customCarousel {

            img {
                min-height: 425px;
                max-height: 425px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .customCarousel {

            img {
                min-height: 450px;
                max-height: 450px;
            }
        }
    }

    @include media-breakpoint-up(xxl) {
        .customCarousel {

            img {
                min-height: 500px;
                max-height: 500px;
            }
        }
    }



    @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            height: 100%;
            .item {
                height: 100%;
            }
            .carouselContainer {
                display: none;
            }
    }
}

.howItWorksContainer {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
}

.carouselContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.customCarousel {
    width: 100%;
    position: relative;

    img {
        min-height: $image-min-height;
        max-height: $image-max-height;
    }
}


.item {
    height: 50%;
    width: 100%;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}

.title {
    font-size: 1.85rem;
    // font-weight: 600;
    margin-bottom: 30px;
    padding-left: 32px;
}

.step {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}


.carouselButton {
    height: 90px;
    width: 40px;
    position: absolute;
    z-index: 1;
    background-color: black;
}

.leftButton {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.rightButton {
    top: 50%;
    transform: translateY(-50%);
    right: 0!important;
}

