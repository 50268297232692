/*=============================================================
                           Main
===============================================================*/

.Main-page {
    padding-top: 110px;
    padding-bottom: 110px;
    /* line-height: 22px; */
    background-color: #262729;
}

.checkout-Main-page {
    padding-top: 110px;
    padding-bottom: 110px;
    /* line-height: 22px; */
    background-color: #262729;
}

@media (max-width: 991.98px) {
    .checkout-Main-page {
        padding-top: 15px;
    }
}

.img-wrap {
    width: 80px;
    height: 80px;
}

.editbtn {
    position: absolute;
    right: 10.2%;
}

.img-wrap a img {
    width: 80px;
    height: 80px;
}

.Product-box {
    border: 1px solid #686868;
    background-color: black;
}

.Product-price {
    line-height: 5px;
}

a {
    text-decoration: none;
}

a:hover {
    color: #E86669;
    /* text-decoration: underline; */
}

.seller-product-page a:hover {
    text-decoration: underline;
}

.btn-Proceed {

    width: 100%;
}

.btn-Proceed-inner {
    color: white;
    background-color: #E86669;
}

#shipping .fa-solid {
    /* font-size: 12px; */
    color: #E86669;
}

.edit {
    color: #E86669;
    font-size: 14px;
}

.fa-trash-can {
    font-size: 24px;
    color: #F47274;
}

.can-can {
    font-size: 1.1rem;
}

.current-price {
    font-size: 17px;
    color: #E86669;

}

.para-txt {
    color: white;
    font-weight: 600;
}

.btn-payment {
    border: 2px solid #E86669;
    background-color: #E86669;
    color: white;
}

.btn-payment:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(28 28 36 / 25%)
}

.origin-price {
    font-size: 14px;
}

.promotion-ratio {
    font-size: 14px;
}

.form-check-input:checked {
    background-color: #E86669;
    border-color: #E86669;
}

.form-check-input:focus {
    border-color: #E86669;
    outline: 0px;
    box-shadow: rgb(232 102 105 / 25%) 0px 0px 0px 0.25rem;
}

#stripe {
    /* font-size: 16px; */
    font-weight: 700;
}

#paynow {
    font-weight: 700;
}


.hide {
    display: none;

}

select {
    border: 2px solid #E86669;
    border-radius: 3px;
}

.show {
    display: block;
}


.pick {
    font-weight: 700;
}

.ninja {
    font-weight: 700;

}


.Pick_input {
    background-color: transparent;
    color: white;
}

.Pick_input option {
    background-color: black;
    color: white;
}

.Pick_input option:hover {
    background-color: #E86669;
    color: white;
}

.Pick_input:focus {
    border-color: #E86669;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(232 102 105 / 25%);
}

.btn-add {
    border: 2px solid #E86669;
    background-color: #E86669;
    color: white;
}


.locker-name {
    font-size: 13px;
    margin: 0;
}


/* .Address-info-item .fa-location-dot{
    position: absolute;
    top: 31%;
   
} */



.fa-phone,
.fa-location-dot,
.fa-envelope,
.fa-user {
    font-size: 0.9rem;
}


/* .fa-envelope{
    position: absolute;
    top: 40%;
} */

/* .fa-user{
    position: absolute;
    top:32% ;
} */

.buyer_field {
    border-bottom: 1px solid #686868 !important;
    border-radius: 1px;
}

.buyer_field:focus {
    box-shadow: none;
}

.del {
    position: relative;
}

.Checkout-form-spinner {
    position: absolute;
    top: 70%;
    left: 12%;
}

.ALert-msg:hover {
    color: #E86669;
}

.fa-plus,
.fa-minus {
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid #686868;

}


.fa-plus:hover,
.fa-minus:hover {
    background-color: #E86669;
    color: white;

}



@media (max-width: 991.98px) {
    .Main-page {
        padding-top: 0px;
        padding-bottom: 110px;
        /* line-height: 22px; */
        background-color: #262729;
    }

    .recent-orders-input {
        width: 98.3%;
    }

    .recent-orders-input1 {
        width: 98.6%;
    }

}

#shipping {
    padding: 20px 0;
    background-color: #fff;
    color: #000;
}

#checkout table,
thead,
tbody,
th,
td {
    border: none !important;
}

#checkout table thead {
    background-color: #d9d9d9 !important;
    color: #000;
    border-radius: 10px;
}

#checkout table thead tr {
    border-radius: 10px;
}

#checkout table th {
    background: none !important;
    color: #000;
}

#checkout .table th:nth-child(3),
.table td:nth-child(3),
.table th:nth-child(4),
.table td:nth-child(4),
.table th:nth-child(5),
.table td:nth-child(5) {
    width: 15%;
}

.curved-header th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.curved-header th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* .card .table tr td {
    text-align: center;
} */

#checkout .card {
    background-color: white !important;
    border-radius: 10px !important;
    padding: 20px !important;
    border: 2px solid #cdcdcd !important;
    /* Adjust other card styles as needed */
}

#checkout .card h4 {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#checkout .card .table {
    width: 100%;
    /* Make the table occupy full width of the card */
    border-collapse: collapse;
    /* Merge borders of table cells */
}

#checkout .card .table-equal-columns th {
    padding: 8px;
}

#checkout .card .table-equal-columns td {
    width: 50%;
    /* Set equal width for table columns */
    padding: 8px;
    /* Adjust padding as needed */
}

#checkout .card .table-equal-columns td:first-child {
    width: 70%;
    /* Set width for the first column */
    border-right: 0;
    /* Remove right border for the first column */
}

#checkout .card .table-equal-columns td:last-child {
    width: 30%;
    /* Set width for the last column */
    border-left: 0;
    /* Remove left border for the last column */
}


#shipping h5 {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#shipping p {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

#shipping .btn-locker {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
    position: relative;
}

#shipping .btn-locker-ship {
    color: #FFF;
    background-color: #F47274;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
    position: relative;
}

#shipping .btn-locker-ship::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 14px;
    /* Increase this value to make the triangle longer */
    border-style: solid;
    border-color: #F47274 transparent transparent transparent;
}

#shipping .btn-locker:hover {
    background-color: #E86669;
    color: #FFF;
}

#shipping .btn-ninja {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    padding: 4px 60px;
    transition: 0.4s;
}

#shipping .btn-ninja:hover {
    background-color: #f47274;
    color: #fff;
}

#shipping ul {
    list-style: none;
    padding: 0;
    /* margin: 0; */
}

#shipping ul li {
    padding-bottom: 10px;
    font-size: 1vw;
}

#shipping ul i {
    font-size: 16px;
    padding-right: 1rem;
    color: #F47274;
    font-size: 1vw;
}

#shipping h6 {
    color: #000;
    text-align: justify;
    font-family: Poppins;
    font-size: 1.1vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
}

#shipping .badge {
    position: absolute;
    top: 1rem !important;
    right: 0;
}

#shipping .card {
    padding: 2.5rem;
    background-color: #fff;
    border: 1px solid #cdcdcd;
}

#shipping .card .postal {
    padding: 2.5rem;
    background-color: #fff;
    border: 1px solid #cdcdcd;
    height: 300px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#shipping .btn-select {
    color: #F47274;
    background-color: #fff;
    font-family: Poppins;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border: 2px solid #F47274;
    border-radius: 50px;
    /* padding: 4px 60px; */
    transition: 0.4s;
    width: 50%;
}

#shipping .btn-select .selected {
    background-color: #F47274;
    color: #fff;
}

#shipping .search {
    position: relative;
}

#shipping .search .search-input {
    background-color: #fff;
    height: 46px;
    width: 100%;
    border-radius: 2rem;
    border: 1px solid #cdcdcd;
    color: #666;
    font-size: 16px;
    text-indent: 10px;
    padding: 0px 15px 0px 45px;
}

#shipping .search i {
    position: absolute !important;
    color: #666;
    left: 20px !important;
    top: 16px !important;
    width: 18px !important;
    height: 18px !important;
    font-size: 16px !important;
}

@media (max-width:500px) {
    .checkout-table {
        overflow-x: scroll;
    }

    .checkout-table::-webkit-scrollbar {
        display: none;
    }

    #checkout .card h4 {
        font-size: 14px;
    }

    #shipping h5 {
        font-size: 14px;
    }

    #shipping p {
        font-size: 12px;
    }

    #shipping .btn-locker-ship {
        font-size: 14px;
        padding: 0px 36px;
    }

    #shipping .btn-ninja {
        font-size: 14px;
        padding: 0px 36px;
    }

    #shipping .btn-locker {
        font-size: 11px !important;
        padding: 0px 20px !important;
    }

    #shipping h6 {
        color: #000;
        text-align: justify;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 1rem;
    }

    #shipping ul li {
        padding-bottom: 10px;
        font-size: 14px;
    }

    #shipping .btn-select {
        color: #F47274;
        background-color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        border: 2px solid #F47274;
        border-radius: 50px;
        /* padding: 4px 60px; */
        transition: 0.4s;
        width: 50%;
    }

    #shipping .card {
        margin-bottom: 20px;
        padding: 15px;
        background-color: #fff;
        border: 1px solid #cdcdcd;
    }

    #shipping .card .postal {
        padding: 34px;
        /* padding: 2.5rem; */
        background-color: #fff;
        border: 1px solid #cdcdcd;
        height: 300px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}