
@mixin media-breakpoint-up($breakpoint) {
    @if($breakpoint == sm) {
        @media (min-width: 576px) { 
            @content;
        }
    }
    @else if($breakpoint == md) {
        @media (min-width: 768px) { 
            @content;
        }
    }
    @else if($breakpoint == lg) {
        @media (min-width: 992px) { 
            @content;
        }
    }
    @else if($breakpoint == xl) {
        @media (min-width: 1200px) { 
            @content;
        }
    }
    @else if($breakpoint == xxl) {
        @media (min-width: 1400px) { 
            @content;
        }
    }
}

@mixin media-breakpoint-down($breakpoint) {
    @if($breakpoint == sm) {
        @media (max-width: 575.98px) { 
            @content;
        }
    }
    @else if($breakpoint == md) {
        @media (max-width: 767.98px) { 
            @content;
        }
    }
    @else if($breakpoint == lg) {
        @media (max-width: 991.98px) { 
            @content;
        }
    }
    @else if($breakpoint == xl) {
        @media (max-width: 1199.98px) { 
            @content;
        }
    }
    @else if($breakpoint == xxl) {
        @media (max-width: 1399.98px) { 
            @content;
        }
    }
}

@mixin media-breakpoint-only($breakpoint) {
    @if($breakpoint == sm) {
        @media (min-width: 576px) and (max-width: 767.98px) {
            @content;
        }
    }
    @else if($breakpoint == md) {
        @media (min-width: 768px) and (max-width: 991.98px) {
            @content;
        }
    }
    @else if($breakpoint == lg) {
        @media (min-width: 992px) and (max-width: 1199.98px) {
            @content;
        }
    }
    @else if($breakpoint == xl) {
        @media (min-width: 1200px) and (max-width: 1399.98px) {
            @content;
        }
    }
    @else if($breakpoint == xxl) {
        @media (min-width: 1400px) {
            @content;
        }
    }
}