@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import './variables.scss';

html,
body,
div#root {
    font-family: 'Roboto Condensed', Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.pageBody {
    padding-top: calc($headerHeight + 50px)!important;
    padding-bottom: calc($headerHeight + 50px)!important;
    min-height: 100vh;
}


.featuredProductCarousel{
    .carousel-control-prev, .carousel-control-next{
        background-color: black;
        width: 30px;
        height: 45px;
        top:50%;
        transform: translateY(-50%);
    }
}

.howItWorksCarousel {
    .carousel-control-prev, .carousel-control-next{
        background-color: black;
        width: 30px;
        height: 45px;
        top:50%;
        transform: translateY(-50%);
    }
}

.priceFilterSlider {
    
    .css-cp2j25-MuiSlider-thumb {
        width: 12px;
        height: 12px;
        background-color: white;
        outline: 2px solid black;
    }

    .css-ttgsjq-MuiSlider-track {
        background-color: black;
        border-color: black;
        height: 8px;
    }

    .css-7o8aqz-MuiSlider-rail {
        background-color: #afafaf;
        border-radius: 0;
        height: 8px;
    }
}

.productPagination{

    > :nth-child(1), >:last-child {
        .page-link {
            border: solid 2px black;
            min-width: 100px;
        }
    }

    .page-link {
        border:none;
        color: black;
        min-width: 40px;
        height: 40px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .page-item{
        margin: 0 10px;
        padding: 3px;
        &.active {
            border-radius: 50%!important;

            .page-link {
                border-radius: 50%!important;
                background-color: lightgray!important;
               
            }
        }
    }
}