@import '../../shared/styles/mixins.scss';

.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
}

.tab {
    font-weight: 300;
    cursor: pointer;

    &.active {
        border-bottom:  solid 1px black;
        font-weight: 600;
    }
}